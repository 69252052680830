<template>
  <Title :text="resumeLabels.skills" />
  <table>
    <tr>
      <TableLabel :label="skillLabels.programLanguages" />
      <td>{{ skills.programLanguages }}</td>
    </tr>
    <tr>
      <TableLabel :label="skillLabels.frameworksPlatforms" />
      <td>{{ skills.frameworksPlatforms }}</td>
    </tr>
    <tr>
      <TableLabel :label="skillLabels.databases" />
      <td>{{ skills.databases }}</td>
    </tr>
    <tr>
      <TableLabel :label="skillLabels.devOps" />
      <td>{{ skills.devOps }}</td>
    </tr>
    <tr>
      <TableLabel :label="skillLabels.iot" />
      <td>{{ skills.iot }}</td>
    </tr>
    <tr>
      <TableLabel :label="skillLabels.testing" />
      <td>{{ skills.testing }}</td>
    </tr>
    <tr>
      <TableLabel :label="skillLabels.languages" />
      <td>{{ skills.languages }}</td>
    </tr>
    <tr>
      <TableLabel :label="skillLabels.developmentTools" />
      <td>{{ skills.developmentTools }}</td>
    </tr>
    <tr>
      <TableLabel :label="skillLabels.versionControl" />
      <td>{{ skills.versionControl }}</td>
    </tr>
    <tr>
      <TableLabel :label="skillLabels.networking" />
      <td>{{ skills.networking }}</td>
    </tr>
    <tr>
      <TableLabel :label="skillLabels.other" />
      <td>{{ skills.other }}</td>
    </tr>
  </table>
</template>

<script>
import Title from "@/components/Title.vue";
import TableLabel from "@/components/TableLabel.vue";

export default {
  Name: "Skills",

  components: { Title, TableLabel },

  computed: {
    currentLanguage() {
      return this.$store.state.currentLanguage;
    },

    resumeLabels() {
      return this.currentLanguage.resumeLabels;
    },

    skills() {
      return this.currentLanguage.data.skills;
    },

    skillLabels() {
      return this.currentLanguage.skillLabels;
    },
  },
};
</script>

<style scoped>
.circle {
  width: 8px;
  margin-left: 8px;
}

.skill-line {
  display: flex;
}

.contact {
  padding-left: calc(1em);
  margin: 0.25em;
}

.other {
  margin-top: 1.5em;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
</style>
