<template>
  <Title :text="othersLabel" />
  <List :text="others"  />
</template>

<script>
import Title from "@/components/Title.vue";
import List from "@/components/List.vue";

export default {
  name: "Others",
  components: { Title, List },
  computed: {
    currentLanguage() {
      return this.$store.state.currentLanguage;
    },

    othersLabel() {
      return this.currentLanguage.resumeLabels.others;
    },

    others() {
        console.log(this.others);
      return this.currentLanguage.data.others;
    },
  },
};
</script>