<!-- eslint-disable vue/no-side-effects-in-computed-properties -->
<template>
  <Title :text="resumeLabels.project" />
  <section
    class="project-section"
    v-for="(project, index) in reversedProjects"
    :key="index"
  >
    <div class="title-container">
      <SubTitle
        :text="project.title"
        :class="{ 'outstanding-title': project.outstanding }"
      />
      <div v-if="project.outstanding" class="highlight-label">
        {{outstandingProject}}
      </div>
    </div>
    <table>
      <tr>
        <TableLabel :label="projectLabels.time" />
        <td>{{ project.time }}</td>
      </tr>
      <tr>
        <TableLabel :label="projectLabels.position" />
        <td>{{ project.position }}</td>
      </tr>
      <tr>
        <TableLabel :label="projectLabels.teamSize" />
        <td>{{ project.teamSize }}</td>
      </tr>
      <tr>
        <TableLabel :label="projectLabels.technologies" />
        <td>
          <List :text="project.technologies" />
        </td>
      </tr>
      <tr>
        <TableLabel :label="projectLabels.description" />
        <td>
          <List :text="project.description" />
        </td>
      </tr>
      <tr>
        <TableLabel :label="projectLabels.responsibility" />
        <td>
          <List :text="project.responsibility" />
        </td>
      </tr>
      <tr v-if="projectLabels.links">
        <TableLabel :label="projectLabels.links" />
        <td>
          <ListLink :links="project.links" />
        </td>
      </tr>
    </table>
  </section>
</template>

<script>
import Title from "@/components/Title.vue";
import SubTitle from "@/components/SubTitle.vue";
import List from "@/components/List.vue";
import TableLabel from "@/components/TableLabel.vue";
import ListLink from "@/components/ListLink.vue";

export default {
  Name: "Project",

  components: {
    Title,
    SubTitle,
    List,
    TableLabel,
    ListLink,
  },

  computed: {
    currentLanguage() {
      return this.$store.state.currentLanguage;
    },

    resumeLabels() {
      return this.currentLanguage.resumeLabels;
    },

    projectLabels() {
      return this.currentLanguage.projectLabels;
    },

    projects() {
      return this.currentLanguage.data.project;
    },
    
    reversedProjects() {
      const reversed = [...this.projects];
      return reversed.reverse();
    },
    outstandingProject() {
      return this.currentLanguage.outstandingProject;
    }
  },
  methods: {
    boldLabel(text) {
      return `<strong>${text}</strong>`;
    },
  },
};
</script>

<style scoped>
.project-section {
  margin-bottom: 2em;
  margin-top: 20px;
}
.title-container {
  position: relative;
  display: inline-block;
}
.outstanding-title {
  border: 2px solid orange;
  padding: 0.5em;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  position: relative; /* cần để div .highlight-label có vị trí chính xác */
}

.highlight-label {
  position: absolute;
  top: -18px;
  left: 0px;
  font-size: 12px;
  color: white;
  background-color: orange;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 2px 8px;
  font-weight: bold;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
@media print {
  .highlight-label {
    background-color: orange !important;
    -webkit-print-color-adjust: exact; /* Dành cho WebKit/Blink */
    print-color-adjust: exact; /* Dành cho tiêu chuẩn CSS */
  }

  .outstanding-title {
    border-color: orange !important;
  }
}
</style>
