<template>
  <div class="a4">
    <Resume />
    <button v-if="showBackToTop" class="back-to-top" @click="scrollToTop">
      <img src="@/assets/icons/arrow-up.svg" width="20"/>
    </button>
  </div>
</template>


<script>
import Resume from "./components/Resume";

export default {
  name: "App",
  components: {
    Resume,
  },
  data() {
    return {
      showBackToTop: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleScroll() {
      this.showBackToTop = window.scrollY > 200;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>



<style>
@import '~@/assets/styles/fonts.css';
/* @import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap'); */

#app {
  box-sizing: border-box;
  font-family: 'Plus Jakarta Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

body {
  margin: 0;
  background: #f1f1f1;
}

.a4 {
  background: #f1f1f1;
  display: block;
  margin: 0 auto;
  margin-top: 0.5cm;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.2cm rgba(0, 0, 0, 0.2);
  width: 21cm;
  min-height: 29.7cm;
  border-radius: 10px;
  position: relative;
}

@media (max-width: 21cm) {
  .a4 {
    width: auto !important;
    min-height: auto !important;
    box-shadow: none !important;
    margin: 0 !important;
    margin-top: 0;
  }
}

@media print {
  .a4 {
    margin: 0;
    width: 25cm !important;
  }
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #007f96;
  border: none;
  padding: 7px 8px;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.back-to-top:hover {
  background: #0056b3;
}
</style>

