<template>
<div>
  <strong v-if="label">{{ label }}: </strong>
  <span>{{ text }}</span>
</div>
</template>

<script>
export default {
  Name: "Paragraph",

  props: {
    text: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: String,
    }
  },
};
</script>

<style scoped>
p {
  line-height: 22px;
  margin: 0;
  font-size: 15px;
  color: #303030;
  text-align: justify;
}
</style>
