<template>
  <div>
    <p v-for="(item, index) in text" :key="index" class="text-item">
      <img class="circle" src="@/assets/icons/dot.svg" />
      {{ item }}
    </p>
  </div>
</template>

<script>
export default {
  Name: "List",

  props: {
    text: {
      type: Array,
      required: true,
      default: () => []
    },
  },
};
</script>

<style scoped>
p {
  line-height: 22px;
  margin: 0;
}
ul {
  margin: 0px;
}

.circle {
  width: 8px;
  margin: 0px 4px 2px 0px;
}
</style>
