<template>
  <td class="label-column" v-if="label">
    <strong>{{ label }}</strong>
  </td>
</template>

<script>
export default {
  name: "TableLabel",
  props: {
    label: {
      type: String,
    },
  },
};
</script>

<style scoped>
.label-column {
  white-space: nowrap;
}
</style>
