<template>
  <h1>{{ text }}</h1>
</template>

<script>
export default {
  name: "Title",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
h1 {
  font-weight: 800;
  font-size: 24px;
  letter-spacing: 4px;
  margin: 0 0 1em 0;
  text-transform: uppercase;
  display: inline-block; /* Đảm bảo h1 có thể kiểm soát độ rộng của border */
  border-bottom: 3px solid #007f96;
  width: 50px; /* Đặt độ dài của gạch chân */
  white-space: nowrap; /* Ngăn chặn chữ bị ngắt dòng */
  padding-bottom: 5px; /* Thêm khoảng cách giữa chữ và gạch chân */
}

</style>
