<template>
  <div>
    <p v-for="(item, index) in links" :key="index" class="text-item">
      <img class="circle" src="@/assets/icons/link.svg" />
     <a :href="item.url" target="_blank">{{ item.title }}</a>
    </p>
  </div>
</template>

<script>
export default {
  Name: "List",

  props: {
    links: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
};
</script>

<style scoped>
p {
  line-height: 22px;
  margin: 0;
}
ul {
  margin: 0px;
}
.text-item {
  text-align: justify;
}
.circle {
  width: 10px;
  margin: 0px 6px 0px 0px;
}
</style>
