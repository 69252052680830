<template>
  <section class="actions-section">
    <LanguageSwitcher class="language-switcher" />
    <button class="print-button" @click="openPrintPage">
      <img src="@/assets/icons/download.svg" width="22" />
    </button>
  </section>

  <div class="box">
    <div class="person-info">
      <Name class="person-info-name" :text="fullName" />
      <div class="person-info-position-wrapper">
        <span class="static-text">📖</span>
        <div ref="typedElement" class="person-info-position"></div>
      </div>
    </div>
    <div class="person-photo">
      <img class="person-photo-image" src="~@/assets/avatar/photo.jpg" />
    </div>
  </div>
</template>

<script>
import Typed from 'typed.js';
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import Name from "@/components/Name.vue";

export default {
  name: "Header",

  components: { LanguageSwitcher, Name },

  computed: {
    currentResumeData() {
      return this.$store.state.currentLanguage.data;
    },

    currentLanguage() {
      return this.$store.state.currentLanguage.code;
    },

    fullName() {
      const data = this.currentResumeData;
      const firstName = data.name["first"];
      const lastName = data.name["last"];
      return this.currentLanguage === 'en' ? `${firstName} ${lastName}` : `${lastName} ${firstName}`;
    },

    position() {
      return this.currentResumeData.position;
    },
  },

  watch: {
    position() {
      this.initTyped();
    }
  },

  mounted() {
    this.initTyped();
  },

  methods: {
    initTyped() {
      if (this.typed) {
        this.typed.destroy();
      }
      const options = {
        strings: this.position,
        typeSpeed: 50,
        backSpeed: 50,
        backDelay: 2000,
        loop: true,
        showCursor: false,
      };
      this.typed = new Typed(this.$refs.typedElement, options);
    },

    openPrintPage() {
      window.print();
    },
  },
};
</script>

<style scoped>
.actions-section {
  display: flex;
  position: absolute;
  align-items: center;
}

.language-switcher {
  padding: 10px;
}

.print-button {
  padding: 10px;
  margin: 10px 10px 10px -10px;
  width: auto;
  height: auto;
  border: none !important;
  cursor: pointer;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
}

.print-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease-out;
  border-radius: 10px;
}

.person-info {
  display: flex;
  flex-direction: column;
}

.person-info-name {
  margin: 0;
  font-weight: 800;
  font-size: 2em;
  line-height: 1.2em;
}

.person-info-position {
  margin: 0px;
  font-size: 1.2em;
  display: inline-block;
  color: #007f96;
  font-weight: 700;
}

.box {
  padding: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.person-photo-image {
  border-radius: 50%;
  height: 100px;
  width: 100px;
}

@media (max-width: 21cm) {
  .box {
    padding-top: 20px;
    flex-direction: column-reverse;
  }

  .person-info {
    align-items: center;
    justify-content: center;
  }

  .person-photo {
    padding-bottom: 20px;
  }

  .person-info-name,
  .person-info-position {
    text-align: center;
  }

  .actions-section {
    width: 100%;
    justify-content: space-between;
  }
}

@media print {
  .actions-section {
    display: none;
  }

  .box {
    padding-top: 0px;
    flex-direction: column-reverse;
  }

  .person-photo {
    display: none;
  }
}

.person-info-position-wrapper {
  display: inline-flex;
  align-items: center;
}

.static-text {
  font-size: 1.2em;
  color: #007f96;
  font-weight: 700;
}

.person-info-position {
  font-size: 1.2em;
  color: #007f96;
  font-weight: 700;
}

.static-text::after {
  content: ' ';
  margin-right: 8px;
}

</style>
