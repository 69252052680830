<template>
  <Title :text="contactsLabel" />
  <div v-if="phoneNumber" class="contact-line">
    <img src="@/assets/profile/phone.svg" width="24" />
    <Paragraph class="contact" :text="phoneNumber" />
  </div>
  <div v-if="email" class="contact-line">
    <img src="@/assets/profile/mail.svg" width="24" />
    <Paragraph class="contact" :text="email" />
  </div>
  <div v-if="linkedIn" class="contact-line">
    <img src="@/assets/profile/linkedin.svg" width="24" />
    <a :href="`https://www.linkedin.com/in/${linkedIn}/`" target="_blank">
      <Paragraph class="contact" :text="linkedIn" />
    </a>
  </div>

  <div v-if="address" class="contact-line">
    <img src="@/assets/profile/address.svg" width="24" />
    <Paragraph class="contact" :text="address" />
  </div>
  <div v-if="git" class="contact-line">
    <img src="@/assets/profile/github.svg" width="24" />
    <Paragraph class="contact" :text="git" />
  </div>
  <div v-if="born" class="contact-line">
    <img src="@/assets/profile/born.svg" width="24" />
    <Paragraph class="contact" :text="born" />
  </div>
  <div v-if="web" class="contact-line">
    <img src="@/assets/profile/web.svg" width="24" />
    <Paragraph class="contact" :text="web" />
  </div>
</template>

<script>
import Title from "@/components/Title.vue";
import Paragraph from "@/components/Paragraph.vue";

export default {
  name: "Contacts",

  components: { Title, Paragraph },

  computed: {
    currentLanguage() {
      return this.$store.state.currentLanguage;
    },

    contactsLabel() {
      return this.currentLanguage.resumeLabels.contacts;
    },

    contacts() {
      return this.currentLanguage.data.contacts;
    },

    phoneNumber() {
      return this.contacts.phone;
    },

    email() {
      return this.contacts.email;
    },

    linkedIn() {
      return this.contacts.linkedIn;
    },

    address() {
      return this.contacts.address;
    },
    git() {
      return this.contacts.git;
    },
    born() {
      return this.contacts.born;
    },
    web() {
      return this.contacts.web;
    },
  },
};
</script>

<style scoped>
.contact-line {
  display: flex;
  margin: 0 auto;
  align-items: center;
}

.contact {
  padding-left: 0.2em;
  margin: 0.25em;
}
</style>
