<template>
  <div class="wrapper">
    <div class="header">
      <Header />
    </div>
    <div class="contacts">
      <Contacts />
    </div>
    <div class="profile">
      <Profile />
    </div>
    <div class="skills">
      <Skills />
    </div>
    <div class="experience">
      <Experience />
    </div>
    <div class="education">
      <Education />
    </div>
    <div class="others">
      <Others />
    </div>
    <div class="project">
      <Projects />
    </div>
    <div class="footer">
      <div class="divide"></div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Contacts from "@/components/Contacts.vue";
import Profile from "@/components/Profile.vue";
import Header from "@/components/Header.vue";
import Skills from "@/components/Skills.vue";
import Experience from "@/components/Experience.vue";
import Education from "@/components/Education.vue";
import Projects from "@/components/Projects.vue";
import Footer from "@/components/Footer.vue";
import Others from "@/components/Others.vue";

export default {
  name: "Resume",

  components: {
    Contacts,
    Profile,
    Header,
    Skills,
    Experience,
    Education,
    Projects,
    Others,
    Footer
  },
};
</script>

<style scoped>
.wrapper {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 220px auto auto auto auto;
  grid-template-areas:
    "header header"
    "contacts profile"
    "skills skills"
    "education experience"
    "others others"
    "project project"
    "footer footer";
}

.contacts,
.skills,
.project,
.education,
.others {
  padding: 0px 45px 0 45px;
  margin: 0px 0px 45px 0px;
}

.profile,
.experience {
  padding: 0px 45px 0 0;
  margin: 0px 0px 45px 0px;
}

.project {
  padding: 0px 45px;
  margin: 0px 0px 45px 0px;
}

.footer {
  padding: 0px 45px;
  margin: 0px;
}

.header {
  display: flex;
  grid-area: header;
  background-image: url("~@/assets/backgrounds/wave.svg");
  background-repeat: no-repeat;
  border-radius: 10px;
}

.contacts {
  grid-area: contacts;
}

.profile {
  grid-area: profile;
}

.skills {
  grid-area: skills;
}

.experience {
  grid-area: experience;
}

.education {
  grid-area: education;
}

.others {
  grid-area: others;
}

.project {
  grid-area: project;
}

.footer {
  grid-area: footer;
}

@media (max-width: 21cm) {
  .wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, auto);
    grid-template-areas:
      "header"
      "contacts"
      "profile"
      "experience"
      "skills"
      "education"
      "others"
      "project"
      "footer";
  }

  .header {
    background-image: url("~@/assets/backgrounds/wave-mobile.svg") !important;
    border-radius: 0px;
  }

  .contacts,
  .skills,
  .profile,
  .experience,
  .project,
  .skills,
  .education,
  .others {
    padding: 0px 20px 0 20px !important;
  }
}

@media print {
  .wrapper {
    grid-template-areas:
      "header header"
      "contacts profile"
      "skills skills"
      "education experience"
      "others others"
      "project project"
  }
  .footer {
    display: none;
  }
  @page {
    margin: 24px 32px;
  }
}
.divide {
  border-top: 1px solid #000;
  margin: 0 45px;
}
</style>
